<template>
  <div>
    <div class="tw-mb-4 tw-flex tw-flex-wrap tw-gap-2">
      <div v-if="editMode" class="tw-w-full tw-flex tw-flex-wrap tw-gap-2 tw-justify-between">
        <BaseButton
          :label="`Markeer alles als ${allPicturesPublic ? 'privé' : 'publiek'}`"
          label-icon="eye"
          color="success"
          @click="toggleAllPicturesVisibility"
        />
        <div class="tw-flex tw-flex-wrap tw-gap-2">
          <BaseButton
            v-if="!saving"
            label="Opslaan"
            label-icon="save"
            color="primary"
            @click="editPictures"
          />
          <span v-else class="tw-bg-primary tw-px-4 tw-py-2 tw-text-white tw-text-center tw-rounded-md tw-text-sm">
            <i class="fas fa-spinner-third fa-spin tw-mr-1" />
            Opslaan...
          </span>
          <BaseButton label="Annuleren" label-icon="times" color="danger" @click="cancelEditMode" />
        </div>
      </div>
      <template v-else>
        <BaseButton label="Aanpassen" label-icon="pencil" @click="startEditingPictures" />
        <BaseButton label="Foto's toevoegen" label-icon="upload" @click="handleUploadClick" />
        <BaseDropDownButton label="Foto's downloaden (.zip)" label-icon="download" :options="dropdownButtonOptions" />
        <BaseButton label="Automatisch labelen" label-icon="tags" @click="autoLabelPictures" />
        <BaseButton v-if="canOptimize" label="Optimaliseer foto's" label-icon="picture-o" @click="optimizePictures" />
        <BaseButton
          v-if="propertyId && publicPictures.length"
          label="Melding versturen naar eigenaar"
          label-icon="send"
          @click="sendEmailConfirmation"
        />
      </template>
    </div>

    <div :class="['dropzone-container', { 'visible': showDropzone }]">
      <FileDropzone
        :url="fileUploadUrl"
        :image-upload-only="true"
        @file-uploaded="loadPictures"
      />
    </div>

    <!-- Used to show the progress of the image order change (draggable) -->
    <CountdownBar ref="countdown" :timeLeft="3" :timeTotal="3" />

    <!-- Used to display both public and private pictures -->
    <PicturePopUp
      ref="popup"
      :project-id="projectId"
      :property-id="propertyId"
      :pictures="pictures"
      class="tw-w-full"
      @reloadPictures="loadPictures"
    />
    <div
      v-for="collection in photoCollection"
      :key="collection.title"
      class="row"
    >
      <div class="col-lg-12">
        <h2 class="ep-heading">{{ collection.title }}</h2>

        <component
          :is="collection.public ? 'draggable' : 'div'"
          v-model="publicPictures"
          group="pictures"
          @change="collection.public ? handleChange() : null"
          @end="drag = false"
          @start="drag = true"
        >
          <div v-for="picture in collection.pictures" :key="picture.id" class="file-box">
            <div :class="['file', { 'notransition': editMode }]">
              <span class="corner" />

              <div class="image">
                <div v-if="pastingConceptStickerForPictureId === picture.id" class="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
                   <i class="tw-text-xl fas fa-spinner-third fa-spin" />
                </div>
                <img
                  v-else
                  :src="picture.url_thumbnail"
                  class="img-responsive tw-cursor-pointer"
                  @click="zoomImage(picture)"
                />
              </div>

              <div class="file-name">
                <div v-if="!editMode && entity.is_sneak_peek" class="tw-flex tw-justify-between">
                  <div class="tw-flex tw-gap-x-1">
                    <label v-if="picture.is_main_picture" class="label label-success">Hoofdfoto</label>
                    <label v-if="picture.public" class="label label-info">Publiek</label>
                    <label v-if="!picture.public" class="label label-default">Privé</label>
                  </div>
                  <div class="tw-flex tw-gap-x-2">
                    <a :href="picture.url" title="Download">
                      <i class="fa fa-download" />
                    </a>
                    <i v-if="picture.public" class="fa fa-arrows tw-mt-0.5" />
                  </div>
                </div>

                <p v-if="pictures.tags && picture.tags.length">
                  <label v-for="tag in picture.tags" :key="tag" class="label label-default">{{ tag }}</label>
                </p>

                <div v-if="!editMode && picture.project_picture" class="tw-italic tw-text-xs tw-mt-2">Gelinkt aan projectfoto</div>

                <div v-if="editMode" class="tw-flex tw-flex-wrap tw-justify-between tw-gap-2">
                  <div class="tw-flex tw-gap-x-2">
                    <input
                      v-model="picture.public"
                      :id="`picture-public-${picture.id}`"
                      type="checkbox"
                      @change="setPictureIsUsedForSocialMedia(picture, 'public')"
                      :disabled="picture.is_sneak_peek_picture && entity.status === 16"
                    />
                    <label :for="`picture-public-${picture.id}`" class="tw-mb-0">Publiek</label>
                  </div>

                  <div v-if="collection.public" class="tw-flex tw-gap-x-2">
                    <input
                      v-model="picture.is_sales_concept"
                      :id="`picture-sales-concept-${picture.id}`"
                      type="checkbox"
                      :disabled="picture.is_used_for_social_media"
                      @change="pasteConceptSticker(picture)"
                    >
                    <label :for="`picture-sales-concept-${picture.id}`" class="tw-mb-0">Verkoopsconcept</label>
                  </div>

                  <div v-if="collection.public" class="tw-flex tw-gap-x-2">
                    <input
                      v-model="picture.is_used_for_social_media"
                      :id="`picture-social-media-${picture.id}`"
                      type="checkbox"
                      :disabled="
                        !picture.public
                        || picture.delete
                        || (!picture.is_used_for_social_media && picturesUsedForSocialMediaCount >= 5)
                        || picture.is_sales_concept
                      "
                    >
                    <label :for="`picture-social-media-${picture.id}`" class="tw-mb-0">
                      Social media (max. 5)
                    </label>
                  </div>
                </div>
                <div v-if="editMode" class="tw-mt-4 tw-flex tw-flex-wrap tw-justify-between tw-gap-x-2">
                  <div v-if="projectId" class="tw-flex tw-gap-x-2">
                    <input
                      type="checkbox"
                      :checked="picture.is_shared_with_entities"
                      @change="toggleSharedWithEntities(picture.id)"
                    >
                    <label>Kopiëren naar entiteiten</label>
                  </div>
                  <div class="tw-flex tw-gap-x-2 tw-pr-2 tw-w-full">
                    <input
                      v-model="picture.delete"
                      :id="`picture-remove-${picture.id}`"
                      type="checkbox"
                      @change="setPictureIsUsedForSocialMedia(picture, 'delete')"
                    />
                    <label
                      :for="`picture-remove-${picture.id}`"
                      class="tw-mb-0"
                    >
                      Verwijderen
                    </label>
                  </div>
                  <span v-if="asbestosPictures.includes(picture.id)" class="input-error">
                    Dit is een asbestfoto
                  </span>
                </div>
              </div>
            </div>
          </div>
        </component>
      </div>
    </div>
    <PropertyPicturesNotificationModal
      v-if="propertyId"
      ref="ownerNotificationModal"
      :is-automated-question="isAutomatedQuestion"
      :property-id="propertyId"
    />
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'

import PicturePopUp from '@/components/iam/PicturePopUp'
import FileDropzone from '@/components/iam/FileDropzone'
import CountdownBar from '@/components/iam/CountdownBar'
import PropertyPicturesNotificationModal from '@/components/properties/PropertyPicturesNotificationModal'

import { mapActions, mapGetters } from 'vuex'
import { questionModal, startLoadingModal, successModal, errorModal } from '@/modalMessages'
import {
  labelPictures,
  updatePropertyPicturesBulk,
  optimizePropertyPictures,
  pollPictureOptimizationStatus,
  getPropertyConcept,
  pasteConceptStickerOnPicture
} from '@/services/properties'
import { updateProjectPicturesBulk, optimizeProjectPictures, toggleProjectPictureInheritance } from '@/services/projects'
import { poll } from '@/utils/helpers'

export default {
  name: 'EntityPictures',
  components: {
    draggable,
    PicturePopUp,
    FileDropzone,
    CountdownBar,
    PropertyPicturesNotificationModal
  },
  data () {
    return {
      isAutomatedQuestion: false,
      showPublicPictures: true,
      showPrivatePictures: true,
      pictures: [],
      publicPictures: [],
      privatePictures: [],
      pictureToZoom: '',
      editMode: false,
      showDropzone: false,
      dropdownButtonOptions: [
        {
          label: 'Alle foto\'s',
          action: () => this.downloadZip('all')
        },
        {
          label: 'Publieke foto\'s',
          action: () => this.downloadZip('public')
        },
        {
          label: 'Private foto\'s',
          action: () => this.downloadZip('private')
        }
      ],
      // editState is used to restore the state before edits, in case the user cancels the edit mode.
      editState: [],
      // By default we assume all photos are private and user would want to change them to public
      allPicturesPublic: false,
      saving: false,
      asbestosPictures: [],
      pastingConceptStickerForPictureId: null
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),
    propertyId () {
      return this.$route.meta.entity_type === 'property' ? parseInt(this.$route.params.id) : null
    },
    projectId () {
      return this.$route.meta.entity_type === 'project' ? parseInt(this.$route.params.id) : null
    },
    fileUploadUrl () {
      return this.propertyId
        ? `/api/v3/property/${this.propertyId}/pictures/upload`
        : `/api/v3/project/${this.projectId}/pictures/upload`
    },
    photoCollection () {
      return [
        { title: 'Publiek', public: true, pictures: this.publicPictures },
        { title: 'Privé', public: false, pictures: this.privatePictures }
      ]
    },
    picturesUsedForSocialMediaCount () {
      return this.publicPictures.filter(picture => picture.is_used_for_social_media).length
    },
    pictureDownloadOptions () {
      return [
        {
          label: 'Alle foto\'s',
          action: this.downloadZip
        },
        {
          label: 'Publieke foto\'s',
          action: this.downloadZip
        },
        {
          label: 'Private foto\'s',
          action: this.downloadZip
        }
      ]
    },
    canOptimize () {
      return this.pictures.filter(picture => picture.url_optimized === null).length > 0
    },
    property () {
      return this.propertyId ? this.getPropertyById(this.propertyId) : null
    },
    project () {
      return this.projectId ? this.getProjectById(this.projectId) : null
    },
    entity () {
      return this.property || this.project
    },
    allowAutomatedCommunication () {
      return this.property?.allow_automated_communication
    },
    sendPicturesAvailabilityEmailQuestionPreviouslyAsked () {
      return this.property?.meta?.send_pictures_availability_question_previously_asked
    }
  },
  created () {
    this.loadPictures()
  },
  methods: {
    ...mapActions('properties', ['loadPropertyPictures', 'loadProjectPictures', 'loadProperty']),

    storeAndSeparatePictures (data = []) {
      const pictures = cloneDeep(data)

      this.pictures = cloneDeep(data)
      this.publicPictures = []
      this.privatePictures = []

      pictures.forEach(picture => {
        picture.delete = false
        picture.public ? this.publicPictures.push(picture) : this.privatePictures.push(picture)
      })
    },
    handleUploadClick () {
      this.showDropzone = !this.showDropzone
    },
    zoomImage (picture) {
      this.$refs.popup.show(picture.id)
    },
    toggleAllPicturesVisibility () {
      this.allPicturesPublic = !this.allPicturesPublic
      const pictures = this.pictures.map(picture => {
        picture.public = this.allPicturesPublic
        return picture
      })
      this.storeAndSeparatePictures(pictures)
    },
    startEditingPictures () {
      this.editState = [...this.pictures]
      this.editMode = true
    },
    cancelEditMode () {
      if (this.editState.length) this.storeAndSeparatePictures([...this.editState]) // restore old edit state
      this.editState = []
      this.editMode = false
    },
    setPictureIsUsedForSocialMedia (picture, type) {
      if (type === 'public') {
        if (!picture.public && picture.is_used_for_social_media) picture.is_used_for_social_media = false
      } else if (type === 'delete') {
        if (picture.delete && picture.is_used_for_social_media) picture.is_used_for_social_media = false
      }
    },

    async loadPictures () {
      const pictures = this.propertyId
        ? await this.loadPropertyPictures(this.propertyId)
        : await this.loadProjectPictures(this.projectId)
      this.storeAndSeparatePictures(pictures)
    },
    async optimizePictures () {
      try {
        startLoadingModal('De foto\'s worden geoptimaliseerd.')
        const response = this.propertyId
          ? await optimizePropertyPictures(this.propertyId)
          : await optimizeProjectPictures(this.projectId)

        await poll(response.data.id, pollPictureOptimizationStatus, 5000)
        await this.loadPictures()
        successModal('De foto\'s zijn geoptimaliseerd')
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het optimaliseren van de foto\'s gelieve opnieuw te proberen')
      }
    },
    async autoLabelPictures () {
      try {
        startLoadingModal('Foto\'s labelen...')
        const response = await labelPictures(this.propertyId)
        successModal('IAM zal de afbeeldingen automatisch een label toekennen. Kom binnen enkele ogenblikken terug om het resultaat te zien.')
        return response
      } catch {
        errorModal('Er is iets misgegaan bij het label van de foto\'s')
      }
    },
    handleChange () {
      this.$refs.countdown.progress()
      throttle(async () => {
        try {
          let order = 0
          const payload = {
            pictures: this.publicPictures.map(picture => {
              return {
                id: picture.id,
                order: order++, // Note that this is post-increment, assigns first, increments later.
                type: picture.type,
                public: picture.public,
                delete: picture.delete,
                is_sales_concept: picture.is_sales_concept,
                is_used_for_social_media: picture.is_used_for_social_media
              }
            })
          }

          const response = this.propertyId
            ? await updatePropertyPicturesBulk(this.propertyId, payload)
            : await updateProjectPicturesBulk(this.projectId, payload)

          const pictures = response.data
          this.storeAndSeparatePictures(pictures)
        } catch (error) {
          errorModal('Fout bij opslaan van foto\'s, probeer het opnieuw.')
        }
      }, 3500)()
    },
    async editPictures () {
      const pictures = []
      let order = 0
      let privateOrder = 1000
      let askConfirm = false
      let picturePrivateToPublic = false

      this.publicPictures.forEach((picture) => {
        if (picture.delete) askConfirm = true
        if (picture.public) {
          pictures.push({
            id: picture.id,
            order: picture.delete ? -1 : order++,
            is_sales_concept: picture.is_sales_concept,
            is_used_for_social_media: picture.is_used_for_social_media,
            public: picture.public,
            delete: picture.delete
          })
        } else {
          pictures.push({
            id: picture.id,
            order: picture.delete ? -1 : privateOrder++,
            is_sales_concept: picture.is_sales_concept,
            is_used_for_social_media: picture.is_used_for_social_media,
            public: picture.public,
            delete: picture.delete
          })
        }
      })

      this.privatePictures.forEach((picture) => {
        if (picture.delete) askConfirm = true
        if (picture.public) {
          picturePrivateToPublic = true
          pictures.push({
            id: picture.id,
            order: picture.delete ? -1 : order++,
            is_sales_concept: picture.is_sales_concept,
            is_used_for_social_media: picture.is_used_for_social_media,
            public: picture.public,
            delete: picture.delete
          })
        } else {
          pictures.push({
            id: picture.id,
            order: picture.delete ? -1 : privateOrder++,
            is_sales_concept: picture.is_sales_concept,
            is_used_for_social_media: picture.is_used_for_social_media,
            public: picture.public,
            delete: picture.delete
          })
        }
      })

      const payload = { pictures }
      if (!askConfirm) return this.savePictures(payload, picturePrivateToPublic)
      const result = await questionModal('Je hebt afbeeldingen geselecteerd voor verwijdering. Dit kan niet ongedaan gemaakt worden.')
      if (!result.value) return false
      return this.savePictures(payload, picturePrivateToPublic)
    },
    async savePictures (payload, picturePrivateToPublic) {
      try {
        this.saving = true
        this.asbestosPictures = []
        const response = this.propertyId
          ? await updatePropertyPicturesBulk(this.propertyId, payload)
          : await updateProjectPicturesBulk(this.projectId, payload)
        const pictures = response.data

        this.storeAndSeparatePictures(pictures)
        this.editMode = false
        successModal('De aanpassingen waren succesvol.')
        // The question should only be asked once
        // Question should only be asked if property concept is not DIY and is not of type prospect and automated communication is allowed
        if (
          this.property &&
          (picturePrivateToPublic || this.allPicturesPublic) &&
          !this.sendPicturesAvailabilityEmailQuestionPreviouslyAsked &&
          this.property.status !== 1
        ) {
          const conceptResponse = await getPropertyConcept(this.propertyId)
          const isDIY = conceptResponse.data?.type === 6 // Type DIY
          if (!isDIY) {
            await this.loadProperty(this.propertyId)
            if (!this.allowAutomatedCommunication) return pictures
            this.isAutomatedQuestion = true
            this.$refs.ownerNotificationModal.show()
          }
        }
        return pictures
      } catch (error) {
        let message = 'Fout bij het opslaan van de foto\'s, probeer het opnieuw.'
        if (error.response?.data?.error_code === 'CANNOT_DELETE_ASBESTOS_PICTURE') {
          message = 'Je kan geen foto\'s verwijderen die deel uitmaken van een asbestdossier.'
          this.asbestosPictures = error.response?.data?.asbestos_pictures
        }
        errorModal(message)
        console.error(error)
      } finally {
        this.saving = false
      }
    },
    async downloadZip (selector) {
      const url = this.propertyId
        ? `/api/v3/property/${this.propertyId}/pictures/download/${selector}`
        : `/api/v3/project/${this.projectId}/pictures/download/${selector}`
      const win = window.open(url, '_blank')
      if (!win) errorModal('Sta pop-ups toe om het eigenaarsrapport te zien.')
    },
    async toggleSharedWithEntities (pictureId) {
      const response = await toggleProjectPictureInheritance(this.projectId, pictureId)
      return response
    },
    async sendEmailConfirmation () {
      this.isAutomatedQuestion = false
      this.$refs.ownerNotificationModal.show()
    },
    async pasteConceptSticker (picture) {
      try {
        if (!picture.is_sales_concept) return // Only if checkbox was checked
        if (!this.propertyId) return // Only for properties
        const response = await getPropertyConcept(this.propertyId)
        if (![1, 3, 5].includes(response.data?.type)) return // Only for concepts PRESALE (1), RENT-TO-BUY (3) & POWERSALE (5)
        const result = await questionModal(`Wil je de conceptsticker "${response.data.name}" automatisch toevoegen op deze foto?`, '')
        if (!result.value) return
        this.pastingConceptStickerForPictureId = picture.id
        const pasteResponse = await pasteConceptStickerOnPicture(this.propertyId, picture.id)
        const newPictureData = pasteResponse.data
        // We do _not_ want a full reload of all pictures, hence this logic to only replace the data for the processed picture
        newPictureData.url_thumbnail = `${newPictureData.url_thumbnail}?updated=1` // Add query param to have the DOM re-render the image
        newPictureData.is_sales_concept = true // If the code reaches this point, it means the checkbox was checked and we should re-set the data to reflect that
        newPictureData.delete = false
        const publicPictures = this.publicPictures
        const processedPublicPictures = publicPictures.map(publicPicture => publicPicture.id === picture.id ? newPictureData : publicPicture)
        this.publicPictures = processedPublicPictures
        return pasteResponse
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het toevoegen van de conceptsticker')
      } finally {
        this.pastingConceptStickerForPictureId = null
      }
    }
  }
}
</script>

<style scoped>
  .file {
    position: relative;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
  }

  .file:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  }

  .label {
    display: inline-block;
    margin: 1px 0;
  }

  label {
    font-weight: 400;
  }

  .drag-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .notransition {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .dropzone-container {
    display: none;
    border: 0;
    transition: all 0.2s;
  }

  .dropzone-container.visible {
    display: block;
  }

  .ep-heading {
    margin-bottom: 20px;
  }

  .download-icon {
    position: absolute;
    right: 35px;
    bottom: 9px;
  }
</style>
